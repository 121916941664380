<template>
  <div class="container">
    <nav-box :title="title" :back="true"></nav-box>
    <div class="content flex-column">
      <span class="price-title">付款金额</span>
      <h2 class="price">￥{{details.unPaidAmount}}</h2>
      <div class="old-price flex flex-sb">
        <div class="flex">
          <span>原价金额：</span>
          <p>￥{{details.amount}}</p>
        </div>
        <div class="flex">
          <span>优惠金额：</span>
          <p>￥{{details.discountAmount}}</p>
        </div>
      </div>
      <div class="line"></div>
      <span class="pay-title"> 选择结账方式： </span>
      <div class="payment flex">
        <blockquote v-for="item in payment" :key="item.id" @click="checkedPay(item)">
          <img class="payIcon" v-if="item.handleType == 256" src="@/assets/wx.png" alt="" />
          <img class="payIcon" v-else src="@/assets/zfb.png" alt="">
        </blockquote>
      </div>
    </div>
  </div>
</template>

<script>
import navBox from "@/components/nav-box.vue";
export default {
  components: {
    navBox,
  },
  name: "payment",
  data() {
    return {
      title: "选择支付方式",
      details:{},
      payment:[]
    };
  },
  created(){
    this.details = JSON.parse(localStorage.getItem('details')) 
    this.getPayment()
  },
  methods: {
    getPayment(){
      this.$fetch(this.Api.payMent).then(res =>{
        this.payment = res.data.items.filter(item => {
          return item.handleType == 64 || item.handleType == 256
        })

      })
    },
    checkedPay(item){
      this.$router.push({
        path: "/scan",
        query: {
          handleType: item.handleType,
          payMethodId: item.id
        },
      })
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
.content {
  padding: 120px 197px 0 197px;
  align-items: center;
  justify-content: center;
  .price-title {
    font-size: 40px;
    font-weight: 500;
    color: #606876;
    line-height: 58px;
    margin-bottom: 44px;
  }
  .price {
    font-size: 80px;
    font-weight: 500;
    color: #1a1e22;
    line-height: 98px;
    margin-bottom: 44px;
  }
  .old-price {
    width: 100%;
    margin-bottom: 70px;
    font-size: 26px;
    line-height: 38px;
    color: #b9bdc6;
    font-size: 500;
    margin-bottom: 68px;
    p {
      color: #1a1e22;
      text-decoration: line-through;
    }
  }
  .line {
    width: 100%;
    border: 3px dashed #b9bdc6;
    transform: rotate(180deg);
    margin-bottom: 140px;
  }
  .pay-title {
    font-size: 40px;
    line-height: 58px;
    font-weight: 500;
    color: #1a1e22;
    margin-bottom: 105px;
  }
  .payment {
    blockquote{
       margin-right: 84px;
      .payIcon {
      width: 270px;
      height: 270px;
    }
    &:last-child{
      margin-right: 0;
    }
    }
    
  }
}
</style>